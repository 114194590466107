import React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important'
import { addNote, NotesStateType } from './store/reducer';
import { connect } from 'react-redux';
import { CSVLink } from "react-csv";
import filenamify from 'filenamify'
import { colors, toHHMMSS } from './other';
import YouTubePlayer from 'yt-player';

const iframeHeight = 300

const sty = StyleSheet.create({
  body: {
    width: '100vw',
    minHeight: '100vh',
    background: colors.offWhite,
    overflowX: 'hidden'
  },
  container: {
    margin: 'auto',
    maxWidth: 632,
    width: '100%',
    padding: '0 16px 32px 16px',
  },
  h1Container:{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  h1Form: {
    display: 'inline-block',
    flexGrow: 1,
  },
  h1: {
    display: 'inline-block',
    marginRight: 16
  },
  note: {
    padding: 8,
    borderLeft: `5px solid ${colors.darkWhite}`,
    marginBottom: 16,
    lineHeight: 1.4,
    fontSize: 16,
    fontWeight: 'normal'
  },
  h1Input: {
    display: 'inline-block',
    padding: 8,
    width: '100%',
    border: `1px solid ${colors.lightGray}`,
    background: colors.white,
    borderRadius: 2,
  },
  h1InputPending: {
    background: '#FFFF00'
  },
  audioHolder: {
    position:'relative',
    borderRadius: 2,
    marginBottom: 16,
    height: iframeHeight,
    overflow: 'hidden',
  },
  audioOverlay: {
    position: 'absolute',
    width: '100%',
    background: colors.opacity70(colors.darkBlack),
    color: colors.offWhite,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: iframeHeight,
    fontSize: 22,
    fontFamily: 'monospace',
    cursor: 'pointer',
  },
  textarea: {
    fontSize: 16,
    lineHeight: 1.4,
    padding: 16,
    resize: 'none',
    width: '100%',
    border: `1px solid ${colors.lightGray}`,
    background: colors.white,
    borderRadius: 2,
  },
  allNotesContainer: {
    paddingTop: 16,
    display: 'flex',
    flexDirection: 'column-reverse',
  },
  noteContainer: {
    padding: 16,
    position: 'relative',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    borderRadius: 2,
    ':nth-child(2n)': {
      background: colors.white,
    }
  },
  noteMessage: {},
  noteTime: {
    color: colors.blue,
    background: 'none',
    border: 'none',
    cursor: 'pointer',
  },
  link: {
    color: colors.blue,
  },
  exportButtonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    position: 'fixed',
    fontSize: 14,
    right: 16,
    bottom: 16,
    zIndex: 1
  },
  exportButton: {
    background: '#3A8C26',
    border: 0,
    color: colors.offWhite,
    borderRadius: 2,
    padding: 8,
    cursor: 'pointer',
    textDecoration: 'none'
  },
  youtubePlayer: {
  }
})


interface State {
  noteValue: string
  podcastPlaying: boolean
  podcastURL: string
  
  isSoundCloudURLFocused: boolean
  soundCloudURLInputValue: string
  currentTrackTitle: string
}

interface Props {
  addNote: (note: {message: string, position: number, podcastURL: string}) => void
  notes: { [podcastURL: string]: { message: string; position: number; }[] | undefined; }
}


class App extends React.Component<Props, State> {

  state: State = {
    isSoundCloudURLFocused: false,
    noteValue: '',
    podcastPlaying: false,
    podcastURL: 'https://www.youtube.com/watch?v=3qHkcs3kG44',
    soundCloudURLInputValue: 'https://www.youtube.com/watch?v=3qHkcs3kG44',
    currentTrackTitle: 'Joe-Rogan-Experience-Naval-Ravikant'
  }

  textarea:  React.RefObject<HTMLTextAreaElement> 
  input: React.RefObject<HTMLInputElement>
  player?: YouTubePlayer
  
  constructor (props: Props) {
    super(props)
    this.textarea = React.createRef()
    this.input = React.createRef()
    window.onkeydown = () => {
      if (this.textarea.current && this.state.isSoundCloudURLFocused === false) {
        this.textarea.current.focus()
      }
    }
  }

  getVideoHash = (inp: string) => {
    const split = inp.split('v=')
    if (split.length >= 2) {
      return split[1]
    }
    return ''
  }

  componentDidMount () {
    this.player = new YouTubePlayer('#player', {
      height: iframeHeight,
      width: 600,
      related: false,
      fullscreen: false,
      modestBranding: true
    })
    this.player.load(this.getVideoHash(this.state.podcastURL))
  }

  onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({ noteValue: e.currentTarget.value })
    if (this.player) {
      this.player.pause()
      this.setState({ podcastPlaying: false })
    }
  }

  onKeyPress = async (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      if (this.state.noteValue === '') {
        return
      }
      if (this.player) {
        this.player.play()
        const position = Math.floor(this.player.getCurrentTime())
        console.log(position)
        this.props.addNote({
          podcastURL: this.state.podcastURL,
          message: this.state.noteValue,
          position,
        })
        this.setState({ 
          noteValue: '',
          podcastPlaying: true,
        })
      }
    }
  }
  render() {
    const currentNotes = (this.props.notes[this.state.podcastURL] || [])
    return (
    <div className={css(sty.body)}>
      <div className={css(sty.exportButtonContainer)}> 
        <CSVLink
          data={currentNotes.map(n => ({ message: n.message, position: toHHMMSS(n.position)}))}
          filename={`${filenamify(this.state.currentTrackTitle)}-pauseNotes.csv`}
          className={css(sty.exportButton)}
          target="_blank"
          onClick={() => {
          }}
        >
        Export Notes as CSV
        </CSVLink>
      </div>
      <div className={css(sty.container)}>
        <div className={css(sty.h1Container)}>
          <h1 className={css(sty.h1)}>PauseNotes</h1>
          <form
            className={css(sty.h1Form)}
            onSubmit={e => {
              e.preventDefault()
              // if they hit enter and nothing is different
              if (this.state.podcastURL === this.state.soundCloudURLInputValue) {
                return
              }
              this.setState(s => ({ podcastURL: s.soundCloudURLInputValue }))

              if (this.player) {
                this.player.load(this.getVideoHash(this.state.soundCloudURLInputValue), true)
                // podcast.load(this.state.soundCloudURLInputValue, {
                //   callback: (e: any) => {
                //     podcast.getCurrentSound((val: any) => {
                //       const title = val.title || 'unknown-track'
                //       this.setState({ currentTrackTitle: title})
                //     })
                //   }
                // })
                
              }
            }}
          >
            <input
              className={css(sty.h1Input, this.state.soundCloudURLInputValue !== this.state.podcastURL ? sty.h1InputPending : null)}
              placeholder="Enter YouTube URL"
              onFocus={() => this.setState({ isSoundCloudURLFocused: true })}
              onBlur={() => this.setState({ isSoundCloudURLFocused: false })}
              onChange={e => this.setState({ soundCloudURLInputValue: e.target.value })}
              value={this.state.soundCloudURLInputValue}
            />
          </form>
        </div>
        <h2 className={css(sty.note)}>Take notes on videos without worrying
        about missing something,
        then jump around videos via your notes
        <br />
        By <a className={css(sty.link)}  target="_blank" href="https://biwills.com">Ben Williams</a> © 2019</h2>
      <div className={css(sty.audioHolder)}>
        
         <div id="player" className={css(sty.youtubePlayer)}></div>
      </div>
      <textarea
        ref={this.textarea}
        className={css(sty.textarea)}
        value={this.state.noteValue}
        onChange={this.onChange}
        onKeyPress={this.onKeyPress}
        placeholder="start typing to pause the video"
      />
      <div className={css(sty.allNotesContainer)}>
        {currentNotes.map(n => 
          <div className={css(sty.noteContainer)}>
            <div className={css(sty.noteMessage)}>{n.message}</div>
            <button type="button" onClick={() => {

              if (this.player) {
                // seek to half a second before
                this.player.play()
                if (this.player.getState() === 'cued') {
                  setTimeout(() => {
                    if (this.player) {
                      this.player.seek(Math.max(0, n.position - 4))
                    }
                  }, 1500)

                } else {

                  this.player.seek(Math.max(0, n.position - 4))
                }
                // podcast.seekTo(n.position)

              }
            }} className={css(sty.noteTime)}>{toHHMMSS(n.position)}</button>
          </div>
        )}
      </div>
    </div></div>
  );
}}

export default connect((state: NotesStateType) => ({
  notes: state.notes,
}), {
  addNote,
})(App);
