
export const colors = {
    // ordered from lightest to darkest
    /** the brightest white color `#FEFEFE` */
    white: '#FEFEFE',
    /** What should be used for text colors `#f9f9f9` */
    offWhite: '#f9f9f9',
    darkWhite: '#e8e8e8',
    lightGray: '#BBBBBB',
    /** lightest gray that should be used on a white / offWhite bg */
    mediumGray: '#737373',
    gray: '#646464',
    darkGray: '#222427',
    lightBlack: '#1a1a1a',
    darkBlack: '#000000',
  
    /** a new color for the left side only */
    darkPurple: '#3B3640',
  
    // alert colors
    blue: '#008cce',
    lightRed: '#dc5352',
    red: '#d12c2a',
  
    // current color for standard avatar, will need to change
    avatar: {
      blue: '#96d4cf',
      orange: '#ffae19',
      green: '#97DB5C',
    },
  
    /** brand colors */
    orange: '#DBA708',
  
    // external colors
    spotifyGreen: '#1db954',
    soundCloudOrange: '#ff8800',
    twitterBlue: '#1da1f2',
  
    active: {
      orange: '#DBC06E',
      lightGray: '#dddddd',
      twitterBlue: '#4db5f5',
    },
  
    // https://gist.github.com/lopspower/03fb1cc0ac9f32ef38f4
    opacity90: (color: string) => `${color}E6`,
    opacity80: (color: string) => `${color}CC`,
    opacity70: (color: string) => `${color}B3`,
    opacity60: (color: string) => `${color}99`,
    opacity50: (color: string) => `${color}80`,
    opacity40: (color: string) => `${color}66`,
    opacity30: (color: string) => `${color}4D`,
    opacity20: (color: string) => `${color}33`,
    opacity10: (color: string) => `${color}1A`,
  }
  
  
  export const toHHMMSS = (num: number) => {
    const sec_num = Math.floor(num)
    const hours = Math.floor(sec_num / 3600);
    const minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    const seconds = sec_num - (hours * 3600) - (minutes * 60);
  
    const hourStr = hours < 10 ? `0${hours}` : hours
    const minuteStr = minutes < 10 ? `0${minutes}` : minutes
    const secondStr = seconds < 10 ? `0${seconds}` : seconds
  
    return `${hourStr}:${minuteStr}:${secondStr}`
  }
  